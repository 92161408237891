import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const probeRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.ProbeReferenceBook[]>
  >;

const probesCallStateUnit = makeDerivedUnit(probeRefsCallStateUnit).getUnit<
  CallState<Record<M.ProbeReferenceBook['code'], M.ProbeReferenceBook>>
>(probeRefsCallState => {
  if (probeRefsCallState.kind === 'successful') {
    const data = probeRefsCallState.data.reduce<
      Record<M.ProbeReferenceBook['code'], M.ProbeReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.code]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return probeRefsCallState;
});

let wasCalled = false;

function useProbeRefsCallState() {
  const callState = probeRefsCallStateUnit.useState();

  const { call } = services.refLoad.useUnitService(
    probeRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'probe' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = probesCallStateUnit.useState();

  useProbeRefsCallState();

  return callState;
}
