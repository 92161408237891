import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as questions from './questions';
import * as subfeatures from './subfeatures';

export { resetState } from './Component';

export const audioStateUnit = questions.Audio.stateUnit;
export const dateStateUnit = questions.Date.stateUnit;
export const drawStateUnit = questions.Draw.stateUnit;
export const fileStateUnit = questions.File.stateUnit;
export const imageStateUnit = questions.Image.stateUnit;
export const mapStateUnit = questions.Map.stateUnit;
export const matchStateUnit = questions.Match.stateUnit;
export const multipleChoiceStateUnit = questions.MultipleChoice.stateUnit;
export const numberStateUnit = questions.Number.stateUnit;
export const singleChoiceStateUnit = questions.SingleChoice.stateUnit;
export const textStateUnit = questions.Text.stateUnit;
export const videoStateUnit = questions.Video.stateUnit;
export const sensorDataStateUnit = questions.SensorData.stateUnit;

export const feature = makeFeature({
  name: 'Questions',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "questions" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
