import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type BodyInputData = {
  user?: M.UserXShort['login'];
  project_book_task: M.ProjectBookStagePostTask;
};

type InputData = BodyInputData;

type APIOutputData = {
  project_book_task: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
};

type OutputData = {
  projectBookTask: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTask: data.project_book_task,
  };
}

export const post = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `project_book_task/?${convertToSearchParams({ ticket })}`,
  'api-v2-post',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
