import React from 'react';

import * as M from 'types/serverModels';
import { isAccessDenied } from 'utils/types/guards';

import { DependenciesContext } from './DependenciesContext';
import * as questions from './questions';
import { Type } from './types';

type Props = {
  data: M.Question | M.QuizQuestion;
  type: Type;
  num: number;
  initialValue?: any;
  initialText?: Record<M.UUID, string>;
  kind?: 'view' | 'form';
  isDisable?: boolean;
  result?: M.QuizAnswerResult;
  onChange?(): void;
};

export function resetState() {
  Object.values(questions).forEach(x => {
    x.stateUnit.resetState();
  });
}

function Questions({
  data,
  num,
  initialValue,
  initialText,
  kind = 'form',
  type,
  isDisable = false,
  result,
  onChange,
}: Props) {
  return (
    <DependenciesContext.Provider
      question={data}
      kind={kind}
      type={type}
      result={result}
      isAccessDenied={isAccessDenied(initialValue)}
    >
      {(() => {
        switch (data.type) {
          case 'map': {
            return (
              <questions.Map.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                isDisable={isDisable}
                onChange={onChange}
              />
            );
          }
          case 'match': {
            return (
              <questions.Match.Component
                data={data}
                kind={kind}
                num={num}
                initialValue={initialValue}
              />
            );
          }
          case 'string': {
            return (
              <questions.Text.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'date': {
            return (
              <questions.Date.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'draw': {
            return (
              <questions.Draw.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'file': {
            return (
              <questions.File.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'number': {
            return (
              <questions.Number.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'text': {
            return (
              <questions.Text.Component
                data={data}
                num={num}
                isMultiline
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'probe': {
            return (
              <questions.SensorData.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'video': {
            return (
              <questions.Video.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'single_choice': {
            return (
              <questions.SingleChoice.Component
                data={data}
                num={num}
                initialValue={initialValue}
                initialText={initialText}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'multi_choice': {
            return (
              <questions.MultipleChoice.Component
                data={data}
                num={num}
                initialValue={initialValue}
                initialText={initialText}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'image': {
            return (
              <questions.Image.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          case 'sound': {
            return (
              <questions.Audio.Component
                data={data}
                num={num}
                initialValue={initialValue}
                kind={kind}
                onChange={onChange}
              />
            );
          }
          default: {
            return null;
          }
        }
      })()}
    </DependenciesContext.Provider>
  );
}

export const Component = React.memo(Questions);
