import { makeService } from '../../utils';

type InputData = {
  file: File;
};

type OutputData = {
  experiment: {
    experiment_id: string;
    sensors: string[];
  };
};

function extractResponse(data: OutputData): OutputData {
  return data;
}

export const post = makeService<InputData, OutputData, OutputData>(
  () => {
    return `logger/experiment/`;
  },
  'api-v2-post',
  false,
  extractResponse,
  {
    contentType: 'multipart/form-data',
  },
);
