import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { AnswerVariant } from 'utils/business';
import { nonEmptyString } from 'utils/validators';

import { isRequired } from '../../i18nSharedReferences';
import { quizLanguageUnit } from '../../units';
import * as Questions from './questions';

export function makeQuestionInstanceFromServerQuestion(
  question: M.QuizQuestion,
  language: TS.Language,
): Questions.QuestionInstance {
  const t = I18n.makeGetOptionalMultilingTranslation(language);

  const msis = Questions.makeSharedInstanceStateConstructor({
    id: question.uuid,
    title: t(question.title),
    explanation: t(question.description),
    image: question.image,
    questionIsRequired: !((question as M.Question)?.optional ?? true),
    visibility: (question as M.Question)?.visibility,
    answerVisibility: (question as M.Question)?.answer_visibility,
    answer: t(question.answer),
  });

  const makeVariant = (x: M.Variant) =>
    AnswerVariant.makeVariantFromServerVariant({
      variant: x,
      validators: [nonEmptyString(isRequired)],
      getMultilingTranslation: I18n.makeGetMultilingTranslation(
        quizLanguageUnit.getState(),
      ),
    });

  switch (question.type) {
    case 'image':
      return Questions.Image.makeInstance(msis);

    case 'map':
      return Questions.Map.makeInstance(msis, {
        matching: question.matching,
        score: Number(question.score),
      });

    case 'match':
      return Questions.Match.makeInstance(msis, question);

    case 'multi_choice':
      return Questions.VariantSelection.makeInstance(msis, {
        hasMultipleChoice: true,
        variants: question.variants.map(makeVariant),
        displayKind: 'radio',
      });

    case 'single_choice':
      return Questions.VariantSelection.makeInstance(msis, {
        hasMultipleChoice: false,
        variants: question.variants.map(makeVariant),
        displayKind: (question as M.SingleChoiceQuestion)?.control?.dropdown
          ? 'select'
          : 'radio',
      });

    case 'number':
      return Questions.Number.makeInstance(msis, {
        matchings: question.matchings,
        score: Number(question.score),
      });

    case 'string':
      return Questions.Text.makeInstance(msis, {
        matching: question.matching,
        score: Number(question.score),
        isMultiline: false,
      });

    case 'text':
      return Questions.Text.makeInstance(msis, {
        matching: [],
        score: Number(question.score),
        isMultiline: true,
      });
  }
}
