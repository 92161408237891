import React, { useEffect, useMemo } from 'react';

import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { ProbeQuestion } from '../../subfeatures';
import { Kind } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';

type Props = {
  data: M.ProbeQuestion;
  num: number;
  kind: Kind;
  initialValue?: string | M.AccessDenied;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<string>>
>({});

function SensorDataQuestion({
  data,
  num,
  initialValue = '',
  kind,
  onChange,
}: Props) {
  const validators = useMemo(
    () => (!data.optional ? [nonEmptyString(questionIsRequired)] : []),
    [data.optional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: '',
    initialValue: typeof initialValue === 'string' ? initialValue : '',
    validators,
  });

  const value = formElementState.units.value.useState();

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        setTimeout(() => {
          const visited = formElementState.units.visited.getState();

          if (nextState !== prevState && visited) {
            onChange?.();
          }
        });
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component num={num} isNotEmpty={value.length > 0}>
      <ProbeQuestion.Component
        formElementState={formElementState}
        probetypeID={data.probe}
        kind={kind}
        onBlur={onChange}
      />
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(SensorDataQuestion);
