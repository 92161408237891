import { useEffect, useState } from 'react';

import { getCurrentLocation } from './currentLocation';
import { subscribeLocationChange } from './locationSubscription';
import { routeMatches } from './routeMatches';

export function useCurrentLocationIsMatchingTo(pathPatternOrURL: string) {
  // NOTE path-to-regexp parses ":" in json search params as parameters, which leads to runtime error
  // basically we don't need to match search params
  const pathPattern = pathPatternOrURL.split('?')[0];

  const [isMatching, setIsMatching] = useState(() => {
    const location = getCurrentLocation();

    return location ? routeMatches(location.pathname, pathPattern) : false;
  });

  useEffect(() => {
    const location = getCurrentLocation();

    if (location && routeMatches(location.pathname, pathPattern)) {
      setIsMatching(true);
    }

    return subscribeLocationChange(location => {
      setIsMatching(routeMatches(location.pathname, pathPattern));
    });
  }, [pathPattern]);

  return isMatching;
}
