import { USER_ROLE } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';

export const useHasUserExclusiveRight = (
  ...users: (Record<string, M.UserXShort> | undefined)[]
) => {
  const userState = userStateUnit.useState();

  switch (userState.kind) {
    case 'loaded': {
      return (
        userState.user.role === USER_ROLE.moderator ||
        users.some(x => x?.hasOwnProperty(userState.user.login))
      );
    }
    default: {
      return false;
    }
  }
};
