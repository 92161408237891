import { useEffect } from 'react';

import * as M from 'types/serverModels';
import { makeDerivedUnit, PrimaryStateUnit } from 'utils/State';

import * as services from '../services';
import { CallState } from '../types';

const timezoneRefsCallStateUnit =
  services.refLoad.makeCallStateUnit() as PrimaryStateUnit<
    CallState<M.TimezoneReferenceBook[]>
  >;

const timezonesCallStateUnit = makeDerivedUnit(
  timezoneRefsCallStateUnit,
).getUnit<
  CallState<Record<M.TimezoneReferenceBook['offset'], M.TimezoneReferenceBook>>
>(timezoneRefsCallState => {
  if (timezoneRefsCallState.kind === 'successful') {
    const data = timezoneRefsCallState.data.reduce<
      Record<M.TimezoneReferenceBook['offset'], M.TimezoneReferenceBook>
    >((acc, x) => {
      return {
        ...acc,
        [x.offset]: x,
      };
    }, {});

    return {
      kind: 'successful',
      data,
    };
  }

  return timezoneRefsCallState;
});

let wasCalled = false;

function useTimezoneRefsCallState() {
  const callState = timezoneRefsCallStateUnit.useState();

  const { call } = services.refLoad.useUnitService(
    timezoneRefsCallStateUnit as PrimaryStateUnit<CallState<M.ReferenceBook[]>>,
  );

  useEffect(() => {
    if (!wasCalled) {
      wasCalled = true;

      call({ ref: 'tz' });
    }
  }, [callState.kind, call]);
}

export function useCallState() {
  const callState = timezonesCallStateUnit.useState();

  useTimezoneRefsCallState();

  return callState;
}
