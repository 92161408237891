import React from 'react';

import * as InfoPopup from 'components/InfoPopup';

import { b } from '../b';

export type Props = {
  useLabel(): string;
  useTooltip?(): React.ReactNode;
  color?: string;
};

export function Label({ useLabel, useTooltip, color = 'grey' }: Props) {
  const Body = () => {
    return <>{useTooltip?.()}</>;
  };

  return (
    <div className={b('label', { color })}>
      {useLabel()}
      {useTooltip && (
        <InfoPopup.Component Body={Body} className={b('tooltip')} />
      )}
    </div>
  );
}

export const Component = React.memo(Label) as typeof Label;
