import * as M from 'types/serverModels';
import { createRequiredContext } from 'utils/react/RequiredContext';

import { Kind, Type } from './types';

type Dependencies = {
  question: M.Question | M.QuizQuestion;
  kind: Kind;
  type: Type;
  result?: M.QuizAnswerResult;
  isAccessDenied: boolean;
};

export const DependenciesContext = createRequiredContext<Dependencies>();
