import { FormElementState, makeFormElementState } from 'utils/FormState';

import {
  QuestionConstructorWidget,
  SharedInstanceStateConstructor,
} from '../../types';
import * as HeaderExtension from './HeaderExtension';
import * as PreviewModeForm from './PreviewModeForm';
import { ServerQuestion, StateInstance } from './types';

export type { StateInstance };

export const constructorWidget: QuestionConstructorWidget<
  'map',
  StateInstance,
  typeof makeInstance
> = {
  key: 'map',
  icon: 'map',
  makeInstance,
  HeaderExtension: HeaderExtension.Component,
  PreviewModeForm: PreviewModeForm.Component,
};

export function makeServerQuestion(
  shouldShowFiguresOnMapState: FormElementState<boolean>,
): Partial<ServerQuestion> {
  return {
    type: shouldShowFiguresOnMapState.units.value.getState() ? 'draw' : 'map',
  };
}

export function makeInstance(
  makeSharedInstanceState: SharedInstanceStateConstructor,
  shouldShowFiguresOnMap = false,
): StateInstance {
  const shouldShowFiguresOnMapState = makeFormElementState<boolean>(
    shouldShowFiguresOnMap,
  );
  return {
    kind: 'map',
    shouldShowFiguresOnMap: shouldShowFiguresOnMapState,
    makeServerQuestion: () => makeServerQuestion(shouldShowFiguresOnMapState),
    ...makeSharedInstanceState(),
  };
}
