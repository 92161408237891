import React from 'react';

import { ConstructorLayout } from 'features/project/Constructor/subfeatures';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../config/configContext';
import * as FormContainer from './FormContainer';
import * as Header from './Header';
import { ProjectWriteContextProvider } from './ProjectWriteContext';
import { StepContext } from './StepContext';
import * as StepsPanel from './StepsPanel';
import * as WriteIntdicator from './WriteIndicator';
import * as modals from './modals';
import * as steps from './steps';
import { Step } from './types';
import { openedStepPreviewUnit } from './units';
import {
  getQuestionInstanceDeletePermission,
  getAnswerDeletePermission,
  getQuestionTypeChangePermission,
} from './utils/permission';

export { steps };

export * from './types';

export { fillMaterialsSteps } from './fillMaterialsSteps';
export { Buttons } from './buttons';

type Props = {};

export function resetState(steps: Step[]) {
  openedStepPreviewUnit.resetState();

  steps.forEach(step => {
    step.resetState();

    if (step.resetStateAfterDelay) {
      setTimeout(step.resetStateAfterDelay, 1000);
    }
  });
}

function FormWithSteps({}: Props) {
  const { activeStepUnit, stepKeyToStep } = useRequiredContext(
    ConstructorConfigContext,
  );

  const activeStepKey = activeStepUnit.useState();
  const activeStep = stepKeyToStep[activeStepKey];

  return (
    <ProjectWriteContextProvider>
      <ConstructorLayout.Component
        header={<Header.Component />}
        stepsPanel={
          <StepsPanel.Component onStepSelect={activeStepUnit.setState} />
        }
        form={
          <>
            <modals.PublishSuccessful.Component />
            <modals.PublishError.Component />
            <modals.SaveError.Component />
            <modals.EditError.Component />
            <steps.PermissionContext.Provider
              getQuestionDeletePermission={getQuestionInstanceDeletePermission}
              getAnswerDeletePermission={getAnswerDeletePermission}
              getQuestionTypeChangePermission={getQuestionTypeChangePermission}
            >
              <StepContext.Provider step={activeStep}>
                <FormContainer.Component />
              </StepContext.Provider>
            </steps.PermissionContext.Provider>
          </>
        }
      />
      <WriteIntdicator.Component />
    </ProjectWriteContextProvider>
  );
}

export const Component = React.memo(FormWithSteps);
