import { Merge } from 'type-fest';

import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';

export function getName(
  stream:
    | M.CourseStreamServiceStreamOutput
    | Merge<
        Pick<M.CourseStreamServiceStreamOutput, 'uuid' | 'name' | 'cdate'>,
        {
          name: I18n.MultilangFormState;
        }
      >,
  lang: TS.Language,
) {
  if (stream.name === undefined) {
    return I18n.getFormattedDate(lang, stream.cdate);
  }

  if ('kind' in stream.name) {
    const name = stream.name.formElementState.units.value.useState();

    return name ? name : I18n.getFormattedDate(lang, stream.cdate);
  }

  return (
    I18n.getMultilingTranslation(lang, stream.name) ||
    I18n.getFormattedDate(lang, stream.cdate)
  );
}

export function useName(
  stream:
    | M.CourseStreamServiceStreamOutput
    | Merge<
        Pick<M.CourseStreamServiceStreamOutput, 'uuid' | 'name' | 'cdate'>,
        {
          name: I18n.MultilangFormState;
        }
      >,
) {
  const lang = I18n.activeLangStateUnit.useState();

  return getName(stream, lang);
}
