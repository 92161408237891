import React, { useEffect, useMemo } from 'react';

import { Checkbox, ErrorMessage, Preloader } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { API, I18n } from 'services';
import { FormElementState } from 'utils/FormState';
import { makeMappingUnitFromUnit, usePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('file-question-form-extension');

type State = Record<string, FormElementState<boolean>>;

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const text = I18n.useText(i18nData).steps.shared.questions.list.file;
  const fileTypesCallState = API.references.fileTypes.useCallState();
  const stateUnit = usePrimaryUnit<State>({});
  const state = stateUnit.useState();
  const mappingState = useMemo(
    () => makeMappingUnitFromUnit(stateUnit),
    [stateUnit],
  ).useState();

  const error = instance.fileCodes.units.error.useState();

  useEffect(() => {
    if (fileTypesCallState.kind === 'successful') {
      const initialValue = instance.fileCodes.getValue();

      stateUnit.setState(prevState => ({
        ...prevState,
        ...Object.keys(fileTypesCallState.data).reduce((acc, x) => {
          if (prevState[x]) {
            prevState[x].units.value.setState(!!initialValue?.includes(x));
            return acc;
          }
          return {
            ...acc,
            [x]: Checkbox.makeFieldState(!!initialValue?.includes(x)),
          };
        }, {}),
      }));
    }
  }, [fileTypesCallState, instance.fileCodes, stateUnit]);

  useEffect(() => {
    if (Object.keys(mappingState).length > 0) {
      instance.fileCodes.units.value.setState(
        Object.entries(mappingState)
          .filter(([, x]) => x.units.value)
          .map(([key]) => key),
      );
    }
  }, [instance.fileCodes.units.value, mappingState]);

  if (Object.keys(state).length === 0) {
    return <Preloader.Component size="xs" />;
  }

  switch (fileTypesCallState.kind) {
    case 'successful':
      return (
        <div className={b()}>
          <h6 className={b('title')}>{text.fileTypesLabel}</h6>
          {Object.values(fileTypesCallState.data).map(x => (
            <Checkbox.Component
              key={x.code}
              formElementState={state[x.code]}
              Label={Checkbox.makeLabel({
                useLabel: () => {
                  const t = I18n.useGetMultilingProjectTranslation();

                  return t(x.name);
                },
                size: 'text-14',
              })}
            />
          ))}
          <ErrorMessage.Component messageReference={error} rows={1} />
        </div>
      );

    case 'pending':
      return <Preloader.Component size="xs" />;

    default:
      return null;
  }
}

export const Component = React.memo(FormExtension);
