import React from 'react';

import { Preloader, Select } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { API, I18n } from 'services';
import * as M from 'types/serverModels';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { FormExtensionProps } from '../../../types';
import { StateInstance } from '../types';
import './style.scss';

const b = block('number-question-form-extension');

function Option({ option }: Select.Option.Props<M.UUID | null>) {
  const t = I18n.useGetMultilingProjectTranslation();

  const label =
    I18n.useText(i18nData).steps.shared.questions.list.number
      .measurementTypeSelectEmptyOption;

  const unitsCallState = API.references.units.useCallState();

  return (
    <Select.Option.Container.DefaultComponent>
      {(() => {
        if (option === null) {
          return label;
        }

        switch (unitsCallState.kind) {
          case 'initial':
          case 'error':
            throw Error("Can't get unit reference");
          case 'pending':
            return <Preloader.Component size="xs" />;
          case 'successful':
            return t(unitsCallState.data[option].name);
        }
      })()}
    </Select.Option.Container.DefaultComponent>
  );
}

function ActiveOption({ option }: Select.Option.Props<M.UUID | null>) {
  const t = I18n.useGetMultilingProjectTranslation();

  const label =
    I18n.useText(i18nData).steps.shared.questions.list.number
      .measurementTypeSelectEmptyOption;

  const unitsCallState = API.references.units.useCallState();

  return (
    <Select.ActiveOption.Container.DefaultComponent
      className={b('unit-select-active-option', {
        placeholder: option === null,
      })}
    >
      {(() => {
        if (option === null) {
          return label;
        }

        switch (unitsCallState.kind) {
          case 'initial':
          case 'error':
            throw Error("Can't get unit reference");
          case 'pending':
            return <Preloader.Component size="xs" />;
          case 'successful':
            return t(unitsCallState.data[option].name);
        }
      })()}
    </Select.ActiveOption.Container.DefaultComponent>
  );
}

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const unitsCallState = API.references.units.useCallState();

  const { step } = useRequiredContext(StepContext);

  if (step.key === 'conclusions') {
    return null;
  }

  switch (unitsCallState.kind) {
    case 'successful':
      return (
        <Select.Component<string | null>
          className={b('unit-select')}
          ActiveOption={ActiveOption}
          Option={Option}
          options={[null, ...Object.keys(unitsCallState.data)]}
          activeOptionState={instance.measurementType}
          errorRows={1}
        />
      );

    case 'pending':
      return <Preloader.Component size="xs" />;

    default:
      return null;
  }
}

export const Component = React.memo(FormExtension) as typeof FormExtension;
