import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  strict?: boolean;
  projects_limit?: number;
};

export const projectShowcase = makeService<InputData, M.ProjectShowcase[]>(
  'project_showcase',
  'get',
);
