import React, { useMemo, useEffect } from 'react';

import recommendationsHeaderIconSrc from 'shared/images/recommendations.svg';
import headerIconSrc from 'shared/images/rulerAndText.svg';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../../config/configContext';
import { ConstructorFormContainer } from '../../subfeatures';
import { StepContext } from '../StepContext';
import { Buttons } from '../buttons';
import * as modals from '../modals';
import * as Header from './Header';
import './style.scss';
import { useStepText } from './useStepText';

type Props = {};

function FormContainer({}: Props) {
  const config = useRequiredContext(ConstructorConfigContext);

  const { step } = useRequiredContext(StepContext);

  const HeaderIcon: ConstructorFormContainer.Props['HeaderIcon'] =
    useMemo(() => {
      return React.memo(({ className }) => {
        const { step } = useRequiredContext(StepContext);

        return (
          <img
            className={className}
            src={
              step.key === 'recommendations'
                ? recommendationsHeaderIconSrc
                : headerIconSrc
            }
            alt="ruler"
          />
        );
      });
    }, []);

  const ButtonsFragment = useMemo(() => {
    return React.memo(() => (
      <Buttons.Component
        projectPageRouteTree={config.projectPageRouteTree}
        kind="regular"
      />
    ));
  }, [config.projectPageRouteTree]);

  useEffect(() => {
    if (config.headerContentUnit) {
      const HeaderContent = React.memo(() => {
        return (
          <ConstructorConfigContext.Provider {...config}>
            <Header.Component />
          </ConstructorConfigContext.Provider>
        );
      });
      config.headerContentUnit.setState(HeaderContent);
    }
  }, [config]);

  return (
    <ConstructorFormContainer.Component
      HeaderIcon={HeaderIcon}
      ButtonsFragment={ButtonsFragment}
      step={step}
      useText={useStepText}
    >
      <modals.InvalidForm.Component />
    </ConstructorFormContainer.Component>
  );
}

export const Component = React.memo(FormContainer);
