import * as M from 'types/serverModels';

export const makeImageState = (data: M.Image): M.ImageInfo => {
  return {
    uuid: data.uuid,
    original: data.large,
    thumb: [
      {
        code: 'large',
        url: data.large,
      },
      {
        code: 'medium',
        url: data.medium,
      },
      {
        code: 'thumb',
        url: data.thumb,
      },
    ],
  };
};
