import React, { useCallback } from 'react';

import { Checkbox, TextArea, TextInput, Button } from 'components';
import { TextEditor } from 'features/abstract';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  QuestionConstructorWidget,
  CachedQuestionInstance,
} from '../../../../types';
import './style.scss';

const b = block('constructor-widget-edit-content-for-question');

type Props = {
  cachedInstance: CachedQuestionInstance;
  constructorWidget: QuestionConstructorWidget<any, any, any>;
};

const RequiredCheckboxLabel = Checkbox.makeLabel({
  useLabel: () =>
    I18n.useText(i18nData).steps.shared.questions.requiredCheckboxLabel,
  size: 'text-14',
});

function EditContent({ constructorWidget, cachedInstance }: Props) {
  const text = I18n.useText(i18nData);

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const stateInstance = cachedInstance.useStateInstance();

  const handleSaveButtonClick = useCallback(() => {
    const isValid = cachedInstance.getStateInstance().validate();

    if (isValid) {
      cachedInstance.mode.setState('preview');
      saveProject();
    }
  }, [cachedInstance, saveProject]);

  const { step } = useRequiredContext(StepContext);

  return (
    <div className={b()}>
      <TextInput.Component
        formElementState={stateInstance.questionText.formElementState}
        placeholder={text.steps.shared.questions.questionTextLabel}
      />
      {(() => {
        switch (step.key) {
          case 'conclusions': {
            return (
              <TextEditor.Component
                className={b('question-explanation-textarea')}
                formElementState={
                  stateInstance.questionExplanation.formElementState
                }
                placeholder={
                  text.steps.shared.questions.questionExplanationLabel
                }
                errorRows={0}
              />
            );
          }
          default: {
            return (
              <TextArea.Component
                className={b('question-explanation-textarea')}
                formElementState={
                  stateInstance.questionExplanation.formElementState
                }
                placeholder={
                  text.steps.shared.questions.questionExplanationLabel
                }
                errorRows={0}
                rows={5}
              />
            );
          }
        }
      })()}
      {constructorWidget.FormExtension && (
        <constructorWidget.FormExtension instance={stateInstance} />
      )}
      <div className={b('footer')}>
        <Button.Component
          type="button"
          onClick={handleSaveButtonClick}
          color="accent-2"
          variant="contained"
        >
          {text.saveButtonLabel}
        </Button.Component>
        {step.key === 'conclusions' ? null : (
          <Checkbox.Component
            className={b('required-checkbox')}
            Label={RequiredCheckboxLabel}
            formElementState={stateInstance.isRequired}
          />
        )}
      </div>
    </div>
  );
}

export const Component = React.memo(EditContent) as typeof EditContent;
