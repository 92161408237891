import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  task_uuid: Exclude<M.ProjectBookTask['uuid'], undefined>;
};

type BodyInputData = {
  state: Exclude<M.ProjectBookTask['state'], undefined>;
  order?: M.ProjectBookTask['order'];
};

type InputData = PathInputData & BodyInputData;

type APIOutputData = {
  project_book_task: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'state'
  >;
};

type OutputData = {
  projectBookTask: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'state'
  >;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTask: data.project_book_task,
  };
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, task_uuid }, convertToSearchParams) =>
    `project_book_task/${task_uuid}/state/?${convertToSearchParams({
      ticket,
    })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    contentType: 'application/x-www-form-urlencoded',
    extractBodyInput: input => {
      const { ticket, task_uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
