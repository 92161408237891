import * as R from 'ramda';
import React, { useCallback } from 'react';

import { VerticallyMovableElement, Radio, Button } from 'components';
import { StepContext } from 'features/project/Constructor/FormWithSteps/StepContext';
import { EditError } from 'features/project/Constructor/FormWithSteps/modals';
import i18nData from 'features/project/Constructor/i18n.json';
import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { useText } from 'services/I18n';
import { ReactComponent as PlusIcon } from 'shared/images/plus.svg';
import { AnswerVariant } from 'utils/business';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';
import { nonEmptyString } from 'utils/validators';

import { useIsEditAccess } from '../../../..';
import { FormExtensionProps } from '../../../types';
import { DisplayKind, StateInstance } from '../types';
import * as Variant from './Variant';
import * as VariantsError from './VariantsError';
import './style.scss';

const b = block('variant-selection-question-form-extension');

const useVerticallyMovableElements =
  VerticallyMovableElement.makeUseVerticallyMovableELements<AnswerVariant.AnswerVariant>(
    x => x.id,
  );

const useVariantSelectionText = () =>
  useText(i18nData).steps.shared.questions.list.variantSelection;

const displayKindToRadioControlConfig: Record<
  DisplayKind,
  Radio.ControlConfig
> = {
  radio: {
    Label: Radio.Button.TextLabel.makeComponent({
      useLabel: () =>
        useVariantSelectionText().displayKindRadioGroup.radioLabel,
    }),
  },
  select: {
    Label: Radio.Button.TextLabel.makeComponent({
      useLabel: () =>
        useVariantSelectionText().displayKindRadioGroup.selectLabel,
    }),
  },
};

const DisplayKindRadioGroupLabel = Radio.ButtonGroup.Label.makeComponent({
  useLabel: () => useVariantSelectionText().displayKindRadioGroup.label,
});

function FormExtension({ instance }: FormExtensionProps<StateInstance>) {
  const text = useVariantSelectionText();
  const { isEditAccess, errorCode } = useIsEditAccess();

  const { step } = useRequiredContext(StepContext);

  const handleAddVariantButtonClick = useCallback(() => {
    if (!isEditAccess && step.key === 'inputDataForm') {
      EditError.errorCodeUnit.setState(errorCode);
      return;
    }
    instance.variants.setState(prev => [
      ...prev,
      AnswerVariant.makeNewVariant([nonEmptyString(isRequired)]),
    ]);
  }, [errorCode, instance.variants, isEditAccess, step.key]);

  const handleVariantMoveDown = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      instance.variants.setState(prev => {
        const index = prev.findIndex(x => x === variant);

        if (index === -1 || index === prev.length - 1) {
          return prev;
        }

        return R.move(index, index + 1, prev);
      });
    },
    [instance.variants],
  );

  const handleVariantMoveUp = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      instance.variants.setState(prev => {
        const index = prev.findIndex(x => x === variant);

        if (index === -1 || index === 0) {
          return prev;
        }

        return R.move(index, index - 1, prev);
      });
    },
    [instance.variants],
  );

  const handleVariantRemove = useCallback(
    (variant: AnswerVariant.AnswerVariant) => {
      if (!isEditAccess && step.key === 'inputDataForm') {
        EditError.errorCodeUnit.setState(errorCode);
        return;
      }
      instance.variants.setState(prev => R.without([variant], prev));
      // TODO return or fix code for compact constructor
      // const permission = getAnswerDeletePermission(instance.id, variant.id);
      // if (permission.kind === 'denied') {
      //   errorModals.UnpermittedManipulationWithWidgetData.openModal({
      //     associatedWidgets: permission.associatedWidgets,
      //     manipulationKind: 'answer-delete',
      //   });
      // } else {
      //   instance.variants.setState(prev => R.without([variant], prev));
      // }
    },
    [errorCode, instance.variants, isEditAccess, step.key],
  );

  const movableVariants = useVerticallyMovableElements(
    instance.variants.useState(),
  );
  const hasMultipleChoice = instance.hasMultipleChoice.units.value.useState();

  const handleChangePredicate = useCallback(
    (key: DisplayKind) => {
      if (!isEditAccess && step.key === 'inputDataForm') {
        EditError.errorCodeUnit.setState(errorCode);
        return;
      }
      instance.displayKind.units.value.setState(key);
    },
    [errorCode, instance.displayKind.units.value, isEditAccess, step.key],
  );

  return (
    <div className={b('element')}>
      {movableVariants.map(variant => {
        return (
          <VerticallyMovableElement.Component
            key={variant.value.id}
            element={variant}
          >
            <Variant.Component
              key={variant.value.id}
              variant={variant.value}
              onMoveDown={handleVariantMoveDown}
              onMoveUp={handleVariantMoveUp}
              onRemove={handleVariantRemove}
            />
          </VerticallyMovableElement.Component>
        );
      })}
      <VariantsError.Component
        variantsSectionStateUnit={instance.variantsSectionState}
      />
      <Button.Component
        type="button"
        size="s"
        color="secondary"
        variant="outlined"
        className={b('add-variant-link')}
        onClick={handleAddVariantButtonClick}
      >
        <PlusIcon className={b('plus-icon')} />
        {text.addVariantButtonLabel}
      </Button.Component>
      {!hasMultipleChoice && step.key !== 'conclusions' && (
        <Radio.ButtonGroup.Component
          className={b('display-kind-radio-group')}
          Label={DisplayKindRadioGroupLabel}
          keyToControlConfig={displayKindToRadioControlConfig}
          formElementState={instance.displayKind}
          errorRows={0}
          onChangePredicate={handleChangePredicate}
        />
      )}
    </div>
  );
}

export const Component = React.memo(FormExtension) as typeof FormExtension;
