import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  data: {
    uuid?: M.UUID;
    group?: string;
    project: M.UUID;
    answers: M.QuestionnaireData['answers'];
    custom: Record<string, Record<M.UUID, string>>;
  };
};

type Output = {
  data: M.QuestionnaireData | M.QuestionnaireData[];
};

export const post = makeService<
  InputData,
  Output,
  M.QuestionnaireData | M.QuestionnaireData[]
>(
  () => 'data/',
  'api-v2-post',
  false,
  ({ data }) => data,
);
