import { ProjectPageRouteTree, routeTree } from 'pages/routeTree';
import * as M from 'types/serverModels';
import {
  PrimaryStateUnit,
  makeDerivedUnit,
  makePrimaryUnit,
} from 'utils/State';
import { makeMapping } from 'utils/collection';

import * as F from '../FormWithSteps';
import { ConstructorMode } from '../types';
import { serverProjectDataUnit } from '../units';
import { ConstructorConfig } from './Config';

export const compactConstructorStepsSections: F.StepsSection[] = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.ProjectAnnouncement.step,
  ],
];

if (process.env.RAZZLE_PROFILE !== 'MESCH') {
  compactConstructorStepsSections.push([F.steps.Access.step]);
}

export const fullConstructorStepsSections: F.StepsSection[] = [
  [
    F.steps.Concept.step,
    F.steps.InputDataForm.step,
    F.steps.Instruction.step,
    F.steps.FindingsSettings.step,
    F.steps.Conclusions.step,
    F.steps.ProjectAnnouncement.step,
  ],

  [F.steps.TeacherMaterials.step, F.steps.ParticipantMaterials.step],

  [F.steps.Access.step, F.steps.Recommendations.step],
];

type Args = {
  mode: ConstructorMode;
  headerContentUnit?: PrimaryStateUnit<React.FC | null>;
  projectUUID?: M.Project['uuid'];
  projectPageRouteTree?: ProjectPageRouteTree;
  onProjectSave?(project: M.Project): void;
  onQuestionnaireDelete?(event: { questionnaire: M.QuestionnaireData }): void;
};

export function makeConstructorConfig({
  mode,
  headerContentUnit,
  projectUUID,
  projectPageRouteTree,
  onProjectSave,
  onQuestionnaireDelete,
}: Args): ConstructorConfig {
  const stepsSections =
    mode === 'compact'
      ? compactConstructorStepsSections
      : fullConstructorStepsSections;

  const steps = stepsSections.flat();

  const stepKeyToStep = makeMapping(
    steps,
    x => x.key,
    x => x,
  );

  const activeStepUnit = makePrimaryUnit<F.StepKey>(
    process.env.RAZZLE_MOCK_CONSTRUCTOR_DATA === 'yes'
      ? mode === 'compact'
        ? 'findingsSettings'
        : 'findingsSettings'
      : steps[0].key,
  );

  const isLastStepUnit = makeDerivedUnit(activeStepUnit).getUnit(
    active => steps[steps.length - 1].key === active,
  );

  const getProjectUUID = () => {
    if (projectUUID) {
      return projectUUID;
    }
    const params =
      routeTree.LANG.project.constructor.PROJECT_UUID.getRouteParams();

    if (params) {
      return params.project_uuid;
    }
    return serverProjectDataUnit.getState()?.uuid || null;
  };

  return {
    steps,
    stepKeyToStep,
    activeStepUnit,
    isLastStepUnit,
    stepsSections,
    mode,
    headerContentUnit,
    projectPageRouteTree,
    onProjectSave,
    onQuestionnaireDelete,
    getProjectUUID,
  };
}
