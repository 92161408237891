import * as FormWithSteps from '../FormWithSteps';
import {
  conclusionsCallStateUnitUnit,
  saveMaterialsCallStateUnitUnit,
} from '../FormWithSteps/shared';
import {
  materialsInNonProjectLanguage,
  allMaterialsCallState,
} from '../FormWithSteps/units';
import { fullConstructorStepsSections } from '../config/makeConfig';
import { serverProjectDataUnit, projectReadCallStateUnit } from '../units';

export function resetState() {
  FormWithSteps.resetState(fullConstructorStepsSections.flat());
  serverProjectDataUnit.resetState();
  materialsInNonProjectLanguage.resetState();
  allMaterialsCallState.resetState();
  projectReadCallStateUnit.resetState();
  saveMaterialsCallStateUnitUnit.resetState();
  conclusionsCallStateUnitUnit.resetState();
}
