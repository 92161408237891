import { Get } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = (
  | { course: Get<M.Course, 'tracks.0.uuid'> }
  | { stream: M.Stream['uuid'] }
) & { track: Get<M.Stream, 'tracks.0.uuid'> };

type OutputData = Get<M.Stream, 'tracks.0'> | Get<M.Course, 'tracks.0'>;

export const courseReadTrack = makeService<InputData, OutputData>(
  'course_read_track',
  'get',
);
