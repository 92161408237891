import { API, I18n } from 'services';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { makeDerivedUnit, makePrimaryUnit } from 'utils/State';

import i18nData from './i18n.json';
import { ConstructorMode } from './types';

export const projectNameFormElementState = makeFormElementState(
  I18n.getText(i18nData).introProjectName.inputPlaceholder,
);
export const serverProjectDataUnit = makePrimaryUnit<M.Project | null>(null);
export const projectLanguageUnit = makeDerivedUnit(
  serverProjectDataUnit,
  I18n.activeLangStateUnit,
).getUnit((project, panelLang) => project?.langs?.[0] || panelLang);

export const serverMaterialsUnit = makePrimaryUnit<Record<M.UUID, M.Unit>>({});

export const projectReadCallStateUnit =
  API.services.project.get.makeCallStateUnit();

export const constructorModeUnit = makePrimaryUnit<ConstructorMode>('full');
