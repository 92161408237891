import React, { useCallback } from 'react';

import { Button } from 'components';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import { projectReadCallStateUnit } from 'features/project/Constructor/units';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';

const b = block('next-button');

type Props = {};

function Next({}: Props) {
  const { steps, activeStepUnit, stepKeyToStep, isLastStepUnit } =
    useRequiredContext(ConstructorConfigContext);

  const handleNextButtonClick = useCallback(() => {
    const activeStepKey = activeStepUnit.getState();
    const index = steps.findIndex(x => x.key === activeStepKey);
    const nextKey = steps[index + 1].key;
    stepKeyToStep[activeStepKey].visitedUnit.setState(true);
    activeStepUnit.setState(nextKey);
  }, [activeStepUnit, stepKeyToStep, steps]);

  const text = I18n.useText(i18nData);
  const isLastStep = isLastStepUnit.useState();

  const projectReadCallState = projectReadCallStateUnit.useState();

  return (
    <Button.Component
      className={b()}
      type="button"
      disabled={isLastStep || projectReadCallState.kind === 'pending'}
      onClick={handleNextButtonClick}
    >
      {text.formContainer.nextButtonLabel}
    </Button.Component>
  );
}

export const Component = React.memo(Next) as typeof Next;
