import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  course_uuid: M.Course['uuid'];
  track_uuid?: M.CourseTrackObject['uuid'];
};

type OutputData = M.CourseTrackListTrackObject[];

export const courseTrackList = makeService<InputData, OutputData>(
  'course_track_list',
  'get',
);
