import React from 'react';
import { Link } from 'react-router-dom';

import { Routing } from 'services';
import { block, classnames } from 'utils/classname';

import { ChildEntry as ChildEntryType } from '../../types';
import './style.scss';

const b = block('main-menu-child-entry');

type Props = {
  childEntry: ChildEntryType;
  className: string;
  onSelect(): void;
};

function ChildEntry({ childEntry, className, onSelect }: Props) {
  switch (childEntry.kind) {
    case 'link':
      const isActiveByActive = childEntry.useIsActive?.();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const isActiveByHref = Routing.useCurrentLocationIsMatchingTo(
        childEntry.href,
      );
      const isActive = isActiveByActive ?? isActiveByHref;

      return (
        <Link
          className={classnames(b({ active: isActive }), className)}
          to={childEntry.href}
          onClick={onSelect}
        >
          {childEntry.label}
        </Link>
      );
    case 'action':
      const handleClick = () => {
        onSelect();
        childEntry.onSelect();
      };

      return (
        <div className={classnames(b(), className)} onClick={handleClick}>
          {childEntry.label}
        </div>
      );
  }
}

export const Component = React.memo(ChildEntry) as typeof ChildEntry;
