import React, { useEffect, useMemo } from 'react';

import { ProjectPageRouteTree } from 'pages/routeTree';
import { I18n } from 'services';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { ConstructorConfigContext } from '../../config/configContext';
import i18nData from '../../i18n.json';
import { ConstructorHeader } from '../../subfeatures';
import { projectNameFormElementState } from '../../units';
import { ProjectWriteContext } from '../ProjectWriteContext';
import * as buttons from '../buttons';

type Props = {
  projectPageRouteTree?: ProjectPageRouteTree;
};

function usePlaceholder() {
  return I18n.useText(i18nData).introProjectName.inputPlaceholder;
}

function Header({ projectPageRouteTree }: Props) {
  const constructorConfig = useRequiredContext(ConstructorConfigContext);
  const contextData = useRequiredContext(ProjectWriteContext);

  const Providers: ConstructorHeader.Props['Providers'] = useMemo(
    () =>
      React.memo(({ children }) => {
        return (
          <ConstructorConfigContext.Provider {...constructorConfig}>
            <ProjectWriteContext.Provider {...contextData}>
              {children}
            </ProjectWriteContext.Provider>
          </ConstructorConfigContext.Provider>
        );
      }),
    [constructorConfig, contextData],
  );

  const ButtonsFragment = useMemo(() => {
    return React.memo(() => (
      <buttons.Buttons.Component
        projectPageRouteTree={projectPageRouteTree}
        kind="inside-of-header"
      />
    ));
  }, [projectPageRouteTree]);

  useEffect(() => {
    return projectNameFormElementState.units.value.subscribe({
      name: 'project-name-changed',
      callback: (newState, preState) => {
        if (newState !== preState) {
          contextData.saveProject();
        }
      },
    });
  }, [contextData]);

  useEffect(() => {
    return () => {
      projectNameFormElementState.formNode.reset();
    };
  }, []);

  return (
    <ConstructorHeader.Component
      Providers={Providers}
      ButtonsFragment={ButtonsFragment}
      formElementState={projectNameFormElementState}
      usePlaceholder={usePlaceholder}
    />
  );
}

export const Component = React.memo(Header);
