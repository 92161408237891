import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  substring: string;
  group: string;
};

export const userListGroupInvite = makeService<InputData, M.User[]>(
  'user_list_group_invite',
  'post',
);
