import * as TS from 'types';
import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  data:
    | {
        uuid: M.UUID;
      }
    | { code: string; lang: TS.Language };
};

export const blogRead = makeService<InputData, M.BlogMessage>(
  'blog_read',
  'post-json',
);
