import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: string;
};

export const projectPublish = makeService<InputData, M.Project>(
  'project_publish',
  'post',
);
