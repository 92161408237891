import React from 'react';

import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import { Step } from '../../types';
import { makeStepErrorsUnit, makeStepVisitedUnit } from '../../utils';
import './style.scss';

const b = block('advanced-settings');

type Props = {};

function AdvancedSettings({}: Props) {
  return <div className={b()}>AdvancedSettings</div>;
}

const Component = React.memo(AdvancedSettings) as typeof AdvancedSettings;

export const step: Step = {
  key: 'advancedSettings',
  Form: Component,
  errorsUnit: makeStepErrorsUnit(),
  visitedUnit: makeStepVisitedUnit(),
  progressInvariantUnitsUnit: makePrimaryUnit([]),
  hasSomeInputUnit: makePrimaryUnit(false),
  getProjectData: () => ({}),
  fillFromExistingProject: () => {},
  resetState: () => {},
};
