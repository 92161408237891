import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';
import { makePrimaryUnit } from 'utils/State';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../../i18nSharedReferences';
import { TextQuestion } from '../../subfeatures';
import { Kind } from '../../types';
import * as QuestionLayout from '../QuestionLayout';
import { useFormElementState } from '../useFormElementState';

type Props = {
  data:
    | M.StringQuestion
    | M.TextQuestion
    | M.TextQuizQuestion
    | M.StringQuizQuestion;
  num: number;
  kind: Kind;
  initialValue?: string | M.AccessDenied;
  isMultiline?: boolean;
  onChange?(): void;
};

export const stateUnit = makePrimaryUnit<
  Record<string, FormElementState<string>>
>({});

function Question({
  data,
  num,
  initialValue = '',
  isMultiline = false,
  kind,
  onChange,
}: Props) {
  const isOptional = 'optional' in data && data.optional;

  const hasChanged = useRef(false);

  const validators = useMemo(
    () => (!isOptional ? [nonEmptyString(questionIsRequired)] : []),
    [isOptional],
  );

  const formElementState = useFormElementState({
    uuid: data.uuid,
    stateUnit,
    defaultValue: '',
    initialValue: typeof initialValue === 'string' ? initialValue : '',
    validators,
  });

  const value = formElementState.units.value.useState();

  const handleBlur = useCallback(() => {
    if (hasChanged.current) {
      onChange?.();
    }
    hasChanged.current = false;
  }, [onChange]);

  useEffect(() => {
    stateUnit.setState(prevState => {
      if (prevState[data.uuid] === formElementState) {
        return prevState;
      }
      return {
        ...prevState,
        [data.uuid]: formElementState,
      };
    });
  }, [data.uuid, formElementState]);

  useEffect(() => {
    return formElementState.units.value.subscribe({
      name: 'change-value',
      callback: (nextState, prevState) => {
        if (nextState !== prevState) {
          hasChanged.current = true;
        }
      },
    });
  }, [formElementState, onChange]);

  return (
    <QuestionLayout.Component num={num} isNotEmpty={value.length > 0}>
      <TextQuestion.Component
        formElementState={formElementState}
        isMultiline={isMultiline}
        kind={kind}
        onBlur={handleBlur}
      />
    </QuestionLayout.Component>
  );
}
export const Component = React.memo(Question);
