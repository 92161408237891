import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {
  task_uuid: Exclude<M.ProjectBookTask['uuid'], 'undefined'>;
};

type QueryInputData = {};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_book_task: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
};

type OutputData = {
  projectBookTask: SetRequired<
    M.ProjectBookTask,
    'uuid' | 'number' | 'project_book' | 'stage' | 'cdate' | 'author' | 'status'
  >;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBookTask: data.project_book_task,
  };
}

export const del = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, task_uuid }, convertToSearchParams) =>
    `project_book_task/${task_uuid}/?${convertToSearchParams({ ticket })}`,
  'api-v2-delete',
  false,
  extractResponse,
);
