import { API } from 'services';
import * as M from 'types/serverModels';
import { makePrimaryUnit } from 'utils/State';

export type UserState = LoadedState | PendingState | NullState;

type LoadedState = {
  user: M.User;
  kind: 'loaded';
};

type PendingState = {
  kind: 'pending';
};

type NullState = {
  kind: 'null';
};

export const userStateUnit = makePrimaryUnit<UserState>({
  kind: 'null',
});

export function convertUserStateToCallState(
  userState: UserState,
): API.CallState<M.User> {
  switch (userState.kind) {
    case 'null': {
      return { kind: 'initial' };
    }
    case 'pending': {
      return { kind: 'pending' };
    }
    case 'loaded': {
      return { kind: 'successful', data: userState.user };
    }
  }
}
