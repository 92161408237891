import { makeFeature } from 'services/Feature';

import i18nData from './i18n.json';
import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const audioStateUnit = subfeatures.Questions.audioStateUnit;
export const dateStateUnit = subfeatures.Questions.dateStateUnit;
export const drawStateUnit = subfeatures.Questions.drawStateUnit;
export const fileStateUnit = subfeatures.Questions.fileStateUnit;
export const imageStateUnit = subfeatures.Questions.imageStateUnit;
export const mapStateUnit = subfeatures.Questions.mapStateUnit;
export const multipleChoiceStateUnit =
  subfeatures.Questions.multipleChoiceStateUnit;
export const numberStateUnit = subfeatures.Questions.numberStateUnit;
export const singleChoiceStateUnit =
  subfeatures.Questions.singleChoiceStateUnit;
export const textStateUnit = subfeatures.Questions.textStateUnit;
export const videoStateUnit = subfeatures.Questions.videoStateUnit;
export const sensorDataStateUnit = subfeatures.Questions.sensorDataStateUnit;

export const feature = makeFeature({
  name: 'ProjectQuestionnaireQuestions',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "project-questionnaire-questions" */
      './Component'
    ),
  i18nData,
  i18nSharedReferences: Object.values(i18nSharedReferences),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
});

export const Component = feature.Component;
