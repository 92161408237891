import { Language } from 'types';
import * as M from 'types/serverModels';
import { Course } from 'utils/models';

import { makeService } from './utils';

type InputData = {
  stream: M.Stream['uuid'];
  lang?: Language;
  users?: M.User['login'][];
};

type APIOutputData = M.CourseStreamProgress[];

type OutputData = M.CourseStreamProgressOutput[];

function extractResponse(data: APIOutputData): OutputData {
  return data.map(x => ({
    ...x,
    tracks: x.tracks.map(y => ({
      ...(y as M.CourseStreamProgress['tracks']['0']),
      objects: Course.addKeyToTrackObjects(y.objects),
    })),
  }));
}

export const courseStreamProgress = makeService<
  InputData,
  APIOutputData,
  OutputData
>('course_stream_progress', 'get', true, extractResponse);
