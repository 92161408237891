import * as M from 'types/serverModels';

import { makeService } from './utils';

type InputData = {
  uuid: M.UUID;
};

export const projectCopy = makeService<InputData, M.Project>(
  'project_copy',
  'get',
);
