import { MathJax } from 'better-react-mathjax';
import React from 'react';

import { RoundedListItemMark } from 'components';
import { DependenciesContext } from 'features/Questions/DependenciesContext';
import {
  autocompleteQuestionFormLabelReference,
  autocompleteQuestionViewLabelReference,
  optionalQuestionLabelReference,
} from 'features/Questions/i18nSharedReferences';
import { I18n } from 'services';
import { QUESTION_VISIBILITY } from 'shared/constants';
import { block } from 'utils/classname';
import { parseHTML } from 'utils/react';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../i18n.json';
import { QuestionExplanation, QuestionText } from '../../subfeatures';
import './style.scss';

const b = block('question-layout');

type Props = React.PropsWithChildren<{
  num: number;
  isNotEmpty: boolean;
}>;

function QuestionLayout({ num, isNotEmpty, children }: Props) {
  const text = I18n.useText(i18nData);
  const t = I18n.useGetMultilingProjectTranslation();

  const optionalLabel = I18n.useReference(optionalQuestionLabelReference);
  const autocompleteQuestionFormLabel = I18n.useReference(
    autocompleteQuestionFormLabelReference,
  );
  const autocompleteQuestionViewLabel = I18n.useReference(
    autocompleteQuestionViewLabelReference,
  );

  const { question, kind, type, result, isAccessDenied } =
    useRequiredContext(DependenciesContext);

  const title = t(question.title);
  const description = t(question.description);
  const answer = t(question.answer);
  const image = question.image?.large;

  const isOptional = 'optional' in question && question.optional;
  const visibility = 'visibility' in question && question.visibility;
  const isAutocomplete = visibility === QUESTION_VISIBILITY.disabled;

  const color = (() => {
    switch (result?.eval) {
      case 'noeval':
      case 'correct':
        return 'accent-2';
      case 'semicorrect':
      case 'incorrect':
        return 'error';
      default:
        return 'accent-2';
    }
  })();

  const variant = (() => {
    switch (result?.eval) {
      case 'incorrect':
      case 'correct':
        return 'contained';
      case 'noeval':
      case 'semicorrect':
        return 'outlined';
      default:
        if ((type === 'quiz' && kind === 'form') || !isNotEmpty) {
          return 'outlined';
        }
        return 'contained';
    }
  })();

  const noteKind = (() => {
    if (kind === 'view' && !isNotEmpty && !isAccessDenied) {
      return 'empty';
    }
    if (isAutocomplete) {
      return 'autocomplete';
    }
    if (isOptional && !isAccessDenied) {
      return 'optional';
    }
    return null;
  })();

  return (
    <section className={b()}>
      <div className={b('container')}>
        <div className={b('header')}>
          <div className={b('number')}>
            <RoundedListItemMark.Component color={color} variant={variant}>
              {num}
            </RoundedListItemMark.Component>
          </div>
          <QuestionText.Component questionText={title} />
        </div>
        <div className={b('content')}>
          {description && (
            <QuestionExplanation.Component
              questionExplanation={description}
              kind={type === 'questionnaire' ? 'text' : 'html'}
              className={b('description')}
            />
          )}
          {image && <img className={b('image')} src={image} alt="question" />}
          {(kind === 'form' || isNotEmpty) && !isAccessDenied && children}
          {isAccessDenied && (
            <p className={b('note')}>{text.accessDeniedLabel}</p>
          )}
          {noteKind && (
            <span className={b('note')}>
              {(() => {
                switch (noteKind) {
                  case 'empty':
                    return text.emptyLabel;
                  case 'autocomplete':
                    return kind === 'form'
                      ? autocompleteQuestionFormLabel
                      : autocompleteQuestionViewLabel;
                  case 'optional':
                    return optionalLabel;
                }
              })()}
            </span>
          )}
          {result && result.max > 0 && (
            <p className={b('score')}>
              {text.scoreLabel}: {result.achieved.toFixed(2)} /{' '}
              {result.max.toFixed(2)}
            </p>
          )}
          {kind === 'view' && answer && (
            <MathJax className={b('answer')} dynamic>
              {parseHTML(answer)}
            </MathJax>
          )}
        </div>
      </div>
    </section>
  );
}
export const Component = React.memo(QuestionLayout);
