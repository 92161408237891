import { GeoJsonObject } from 'geojson';
import React from 'react';

import {
  MapQuestion,
  DrawQuestion,
} from 'features/project/Constructor/subfeatures';
import * as M from 'types/serverModels';
import { useFormElementState } from 'utils/FormState';

import { PreviewModeFormProps } from '../../../types';
import { StateInstance } from '../types';

function PreviewModeForm({ instance }: PreviewModeFormProps<StateInstance>) {
  const mapFormElementState = useFormElementState<M.Location | null>(null);
  const figuresFormElementState = useFormElementState<GeoJsonObject | null>(
    null,
  );

  const figures = instance.shouldShowFiguresOnMap.units.value.useState();

  return figures ? (
    <DrawQuestion.Component formElementState={figuresFormElementState} />
  ) : (
    <MapQuestion.Component formElementState={mapFormElementState} optional />
  );
}

export const Component = React.memo(PreviewModeForm) as typeof PreviewModeForm;
