import { Preloader } from 'components';
import { makeFeature } from 'services/Feature';

import * as i18nSharedReferences from './i18nSharedReferences';
import * as subfeatures from './subfeatures';

export const feature = makeFeature({
  name: 'UserProfile',
  componentModuleLoader: () =>
    import(
      /* webpackChunkName: "user-profile" */
      './Component'
    ),
  subfeatures: Object.values(subfeatures).map(x => x.feature),
  Preloader: () => <Preloader.Component size="xs" />,
  i18nSharedReferences: Object.values(
    (() => {
      const { userRoleReference } = i18nSharedReferences;

      return [...Object.values(userRoleReference)];
    })(),
  ),
  i18nData: null,
});

export type { Props } from './Component';

export {
  makeUserNameFormatter,
  useUserNameFormatter,
} from './makeUserNameFormatter';

export const Component = feature.Component;
