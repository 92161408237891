import React, { MouseEventHandler, useCallback } from 'react';

import * as Modal from 'components/Modal';
import { ReactComponent as ExtraIcon } from 'shared/images/extra.svg';
import { PrimaryStateUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import type * as Button from '../Button';
import './style.scss';

type Variant = 'contained' | 'outlined';
type Size = 's' | 'm' | 'l';

const b = block('m-extra-menu');

export type Props = {
  Button?: React.VFC<Button.ProvidedProps>;
  className?: string;
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
  isOpenUnit: PrimaryStateUnit<boolean>;
};

function MExtraMenu({
  Button,
  className,
  variant = 'outlined',
  size = 'm',
  children,
  isOpenUnit,
}: Props) {
  const handleClick = useCallback(() => {
    isOpenUnit.setState(true);
  }, [isOpenUnit]);

  const handleModalClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <>
      {Button ? (
        <Button
          forwardedRef={undefined}
          expanded={undefined}
          counter={React.Children.toArray(children).length}
          onClick={handleClick}
        />
      ) : (
        <button
          className={classnames(b({ variant, size }), className)}
          onClick={handleClick}
        >
          <ExtraIcon className={b('icon')} />
        </button>
      )}
      <Modal.Component
        isOpenUnit={isOpenUnit}
        size="xs"
        desktopOnly
        onClick={handleModalClick}
      >
        <Modal.Layout.Default.Component className={b('list')} as="ul">
          {children}
        </Modal.Layout.Default.Component>
      </Modal.Component>
    </>
  );
}

export const makeIsOpenUnit = Modal.makeIsOpenUnit;
export const useIsOpenUnit = Modal.useIsOpenUnit;

export const Component = MExtraMenu;
