import React, { useCallback } from 'react';

import { Button } from 'components';
import { PayWall } from 'features/global';
import { ConfirmActionModal } from 'features/modals';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  projectReadCallStateUnit,
  serverProjectDataUnit,
} from 'features/project/Constructor/units';
import { I18n } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import i18nData from '../../../i18n.json';
import { ProjectWriteContext } from '../../ProjectWriteContext';
import * as modals from '../../modals';
import { StepErrorsDescription } from '../../types';
import { payWallPublicProjectTextReference } from './../../../i18nSharedReferences';
import './style.scss';

const b = block('publish-button');

type Props = {};

const isOpenUnit = ConfirmActionModal.makeIsOpenUnit();

function Publish({}: Props) {
  const text = I18n.useText(i18nData);
  const defaultPublicProjectPayWallText = I18n.useReference(
    payWallPublicProjectTextReference,
  );

  const { callStateUnit, publish } = useRequiredContext(ProjectWriteContext);

  const { steps, getProjectUUID } = useRequiredContext(
    ConstructorConfigContext,
  );

  const isPrivate = !!serverProjectDataUnit.useState()?.private;

  const publishProject = useCallback(async () => {
    const projectUUID = getProjectUUID();
    if (projectUUID) {
      publish.call({ uuid: projectUUID });
    }
  }, [getProjectUUID, publish]);

  const validate = useCallback(() => {
    steps.forEach(step => {
      step.visitedUnit.setState(true);
    });
    const errorsDescriptions = steps
      .map(
        (x): StepErrorsDescription => ({
          stepName: I18n.makeEntryReference(
            i18nData,
            data => data.steps[x.key].title,
          ),
          errors: x.errorsUnit.getState(),
        }),
      )
      .filter(x => x.errors.length > 0);

    const isValid = errorsDescriptions.length === 0;
    if (isValid) {
      isOpenUnit.setState(true);
    } else {
      modals.InvalidForm.errorsDescriptionsUnit.setState(errorsDescriptions);
      modals.InvalidForm.isOpenUnit.setState(true);
    }
  }, [steps]);

  const handleButtonClick = useCallback(() => {
    PayWall.addCheck(
      PayWall.makeCheck({
        featureCode: 'submit_project',
        blockType: 'action',
        blockedText: defaultPublicProjectPayWallText,
        onHaveFeature: () => {
          validate();
        },
      }),
    );
  }, [defaultPublicProjectPayWallText, validate]);

  const callState = callStateUnit.useState();
  const projectReadCallState = projectReadCallStateUnit.useState();

  return (
    <>
      <Button.Component
        className={b()}
        type="button"
        callStateUnit={publish.callStateUnit}
        disabled={
          projectReadCallState.kind === 'pending' ||
          callState.kind === 'pending'
        }
        onClick={handleButtonClick}
      >
        {text.formContainer.publishButtonLabel}
      </Button.Component>
      <ConfirmActionModal.Component
        isOpenUnit={isOpenUnit}
        onYesClick={publishProject}
      >
        {isPrivate
          ? text.modals.confirmPublish.forPrivate
          : text.modals.confirmPublish.forPublic}
      </ConfirmActionModal.Component>
    </>
  );
}

export const Component = React.memo(Publish) as typeof Publish;
