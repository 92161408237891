import React from 'react';

import { serverProjectDataUnit } from 'features/project/Constructor/units';
import { USER_ROLE } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import { block } from 'utils/classname';

import { Comments } from '../../../../../subfeatures';
import './style.scss';

type Props = {};

const b = block('comments-section');

function CommentsSection({}: Props) {
  const serverProjectData = serverProjectDataUnit.useState();
  const userState = userStateUnit.useState();

  if (serverProjectData === null || userState.kind !== 'loaded') {
    return null;
  }

  const withoutForm =
    !serverProjectData.author[userState.user.login] &&
    userState.user.role !== USER_ROLE.moderator;

  return (
    <Comments.Component
      className={b()}
      object={serverProjectData.uuid}
      collection="project_author"
      withoutForm={withoutForm}
    />
  );
}

export const Component = React.memo(CommentsSection);
